import "react-image-lightbox/style.css";
import "swiper/swiper-bundle.css";

import { ThemeProvider } from "@chakra-ui/core";
import { navigate } from "gatsby";
import queryString from "query-string";
import React from "react";
import Swiper, { Autoplay, Pagination } from "swiper";
import { customTheme } from "./src/theme";
Swiper.use([Pagination, Autoplay]);

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={customTheme}>{element}</ThemeProvider>
);

export const onClientEntry = () => {
  const qs = queryString.parse(document.location.search);
  document.cookie = `utm_source=${qs.utm_source || ""};path=/`;
  document.cookie = `utm_medium=${qs.utm_medium || ""};path=/`;
  document.cookie = `utm_campaign=${qs.utm_campaign || ""};path=/`;
  document.cookie = `gclid=${qs.gclid || ""};path=/`;
};

// Utrzymywanie parametru konwersji reklamy
export const onRouteUpdate = ({ location, prevLocation }) => {
  if (prevLocation && prevLocation.search.indexOf("?gclid=") !== -1) {
    return navigate(location.pathname + prevLocation.search);
  }
};
