// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-b-2-b-self-service-js": () => import("./../../../src/pages/b2b-self-service.js" /* webpackChunkName: "component---src-pages-b-2-b-self-service-js" */),
  "component---src-pages-company-management-js": () => import("./../../../src/pages/company-management.js" /* webpackChunkName: "component---src-pages-company-management-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-dedicated-solutions-js": () => import("./../../../src/pages/dedicated-solutions.js" /* webpackChunkName: "component---src-pages-dedicated-solutions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-project-financing-js": () => import("./../../../src/pages/project-financing.js" /* webpackChunkName: "component---src-pages-project-financing-js" */),
  "component---src-pages-sales-and-marketing-js": () => import("./../../../src/pages/sales-and-marketing.js" /* webpackChunkName: "component---src-pages-sales-and-marketing-js" */),
  "component---src-pages-service-management-js": () => import("./../../../src/pages/service-management.js" /* webpackChunkName: "component---src-pages-service-management-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

